import React, {useState} from "react";
import s from "../report/styles.module.css";
import JudgeAddition from "./JudgeAddition";

const JudgeSettings = () => {
    const [choosePage, setChoosePage] = useState(1)
    return (
        <div>
            <JudgeAddition/>
        </div>
    );
}

export default JudgeSettings;