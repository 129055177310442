import {AdapterDayjs} from "@mui/x-date-pickers/AdapterDayjs";
import {DatePicker} from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import {LocalizationProvider} from "@mui/x-date-pickers/LocalizationProvider";
import React, {useState} from "react";
import s from "./style.module.css";
import api from "../../api/api";
import {toast} from "react-toastify";

const JudgeAddition = () => {

    const [date, setDate] = useState(null);
    const [gender, setGender] = useState(2);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [patronymic, setPatronymic] = useState("");
    const [category, setCategory] = useState("");

    const handleDateChange = (newValue) => {
        const formattedDate = dayjs(newValue?.$d).format('YYYY-MM-DD');
        setDate(formattedDate);
    };

    function handleGenderChange(e) {
        let value = e.target.value;
        if (value === "Женский") {
            setGender(2);
        } else {
            setGender(1);
        }
    }

    async function saveJudge() {
        const result = await api().post("/judge/add-judge", {
            "judge_birthdate": date,
            "judge_category": category,
            "judge_first_name": firstName,
            "judge_gender": gender,
            "judge_last_name": lastName,
            "judge_patronymic": patronymic,
        });

        if (result.data?.status === 'ok') {
            toast.success('Судья успешно добавлен.')
            setDate(null);
            setFirstName("");
            setLastName("");
            setPatronymic("");
            setGender(2);
            setCategory("");

        } else {
            toast.error("Ошибка!")
        }
    }

    return (
        <div className={s.content}>
            <div style={{marginLeft: "100px", textAlign: "center"}}>
                <h1 style={{marginBottom: "20px"}}>Добавление судьи</h1>
                <input className={s.input} type="text" placeholder={"Фамилия"} value={lastName}
                       onChange={(e) => setLastName(e.target.value)}/>
                <input className={s.input} type="text" placeholder={"Имя"} value={firstName}
                       onChange={(e) => setFirstName(e.target.value)}/>
                <input className={s.input} type="text" placeholder={"Отчество"} value={patronymic}
                       onChange={(e) => setPatronymic(e.target.value)}/>
                <input className={s.input} type="text" placeholder={"Судейская категория"} value={category}
                       onChange={(e) => setCategory(e.target.value)}/>
                <select className={s.select_box} defaultValue="Женский" onChange={handleGenderChange}>
                    <option>Мужской</option>
                    <option>Женский</option>
                </select>
                <div className={s.date_wrapper}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            label="Дата рождения"
                            value={date && dayjs(date)}
                            format={'DD.MM.YYYY'}
                            sx={{
                                width: '200px',
                                backgroundColor: 'white',
                            }}
                            slotProps={{textField: {size: 'small'}}}
                            onChange={(newValue) => handleDateChange(newValue)}
                        />
                    </LocalizationProvider>
                </div>
                <div className={s.button} onClick={saveJudge}>Добавить</div>
            </div>
        </div>
    );
}

export default JudgeAddition;